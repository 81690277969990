export type SortOrder = 'ascending' | 'descending'

export type Mode = 'View' | 'Edit'

export type Role = 'ALL' | 'DEMO' | 'PARTNER' | 'TEST'

export type PartnerType = 'All' | 'Partner' | 'Test' | 'Demo'

export type CollectionType = 'Custom' | 'Curated'

export type CollectionSource = 'EMS' | 'Kosmos'

export type CollectionSourceStatus = 'Active' | 'Updated' | 'Deleted'

export type CollectionStatus = 'Active' | 'Inactive'

export type EnvironmentType = 'Local' | 'Dev' | 'Staging' | 'Production'

export type PlaybackStatus = 'pause' | 'play' | 'buffer'

export type DragDirection = 'Up' | 'Down'

export type Apps = 'Internal' | 'DevPortal' | 'Landingpage'

export type ColumnAlign = 'Left' | 'Right' | 'Center'

export type Unit = 'px' | '%' | 'rem' | 'em'

export type Alignment = 'Horizontal' | 'Vertical'

export type TierOption = 'FREE' | 'PAID'

export const INACTIVE_COLLECTION_DEFAULT_SORT = 1000

export const GENERAL_ERROR = 'An error ocurred!'

export const API_FORM_REQUEST_URL =
    'https://docs.google.com/forms/d/1oz7VqvQHmDCEqQX41uEN55NuI8ciou77nIfxpp7Z-7I/edit?urlBuilderDomain=epidemicsound.com&gxids=7628'
