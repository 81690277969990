import { EnvironmentType } from '../utils'

export const Environment = 'environment'

interface EnvironmentConfig {
    value: EnvironmentType
    url?: string
}

export const DEFAULT_API_BASE_URl =
    'https://partner-content-api.epidemicsound.com'

export const UrlByEnvironment: EnvironmentConfig[] = [
    { value: 'Local', url: process.env.NEXT_PUBLIC_LOCAL_API_BASE_URL },
    { value: 'Dev', url: process.env.NEXT_PUBLIC_DEV_API_BASE_URL },
    { value: 'Staging', url: process.env.NEXT_PUBLIC_STAGING_API_BASE_URL },
    {
        value: 'Production',
        url: process.env.NEXT_PUBLIC_PRODUCTION_API_BASE_URL,
    },
]

export const getActiveEnvironments = (): EnvironmentType[] => {
    const environments: EnvironmentType[] = []
    const appEnvironment: EnvironmentType = process.env
        .NEXT_PUBLIC_APP_ENVIRONMENT as EnvironmentType
    switch (appEnvironment) {
        case 'Local':
            if (process.env.NEXT_PUBLIC_LOCAL_API_BASE_URL)
                environments.push('Local')
            break
        case 'Dev':
            if (process.env.NEXT_PUBLIC_DEV_API_BASE_URL)
                environments.push('Dev')
            break
        case 'Staging':
            if (process.env.NEXT_PUBLIC_STAGING_API_BASE_URL)
                environments.push('Staging')
            break
        case 'Production':
            if (process.env.NEXT_PUBLIC_PRODUCTION_API_BASE_URL)
                environments.push('Production')
            break
    }
    return environments
}

const activeEnvironments = getActiveEnvironments()

export const getCurrent = (): EnvironmentType | undefined => {
    const env = window.localStorage.getItem(Environment)
    if (env) {
        return JSON.parse(env)
    }
}

export const getCurrentOrDefault = (): EnvironmentType => {
    let env = getCurrent()
    if (!env) {
        env = process.env.NEXT_PUBLIC_APP_ENVIRONMENT as EnvironmentType
        if (!env) {
            env = activeEnvironments[0]
        }
    }
    return env
}

export const setCurrent = (environment: EnvironmentType) => {
    window.localStorage.setItem(Environment, JSON.stringify(environment))
}

export const isCypress = (): boolean => {
    return (
        typeof window !== 'undefined' &&
        sessionStorage.getItem('CYPRESS_ENVIRONMENT') === 'true'
    )
}

const Environments = {
    getCurrent,
    activeEnvironments,
    setCurrent,
    getCurrentOrDefault,
}

export default Environments
